import type { Feature } from './types';

import { getEnvValue } from '../utils';

const L1BaseUrl = getEnvValue('NEXT_PUBLIC_CITREA_L1_BASE_URL');

const title = 'Citrea Bitcoin Explorer';

const config: Feature<{ L1BaseUrl: string }> = (() => {
  if (L1BaseUrl) {
    return Object.freeze({
      title,
      isEnabled: true,
      L1BaseUrl,
    });
  }

  return Object.freeze({
    title,
    isEnabled: false,
  });
})();

export default config;
